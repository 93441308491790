import { Clue } from "./clue";
import { Row, RowState } from "./Row";
import { gameName, maxGuesses } from "./util";

export function About() {
  return (
    <div className="App-about">
      <p>
        {gameName} is a variation on the word game <a href="https://app.wordl.io" target="_blank" rel="noopener noreferrer">WORDL</a>.
      </p>
      <p>
        {gameName} is <i>unique</i> in many key ways.
      </p>
      <p>
	      {gameName} is food-focused. Players can play multiple times per day. 4-11 letter games coming soon. Players can give up and see the answer.
      </p>
      <p>
        To play, you get {maxGuesses} tries to guess a target word.
        <br />
        After each guess, you get feedback clues.
      </p>
      <hr />
      <Row
        rowState={RowState.LockedIn}
        wordLength={5}
        cluedLetters={[
          { clue: Clue.Correct, letter: "f" },
          { clue: Clue.Elsewhere, letter: "l" },
          { clue: Clue.Elsewhere, letter: "o" },
          { clue: Clue.Absent, letter: "u" },
          { clue: Clue.Absent, letter: "r" },
        ]}
      />
      <p>
      <b className={"green-bg"}>F</b> is placed correctly.
      </p>
      <p>
        <b className={"yellow-bg"}>L</b> and <b className={"yellow-bg"}>O</b> occur <em>elsewhere</em> in the target word.
      </p>
      <p>
	    <b>U</b> and <b>R</b> are not in the target word at all.
      </p>
      <p>
        All letters may occur more than once.
      </p>
      <hr />
      <p>
        Try changing <b>D</b> <b>O</b> <b>X</b> in the next guess.
      </p>
      <Row
        rowState={RowState.LockedIn}
        wordLength={5}
        cluedLetters={[
          { clue: Clue.Correct, letter: "f" },
          { clue: Clue.Correct, letter: "o" },
          { clue: Clue.Elsewhere, letter: "d" },
          { clue: Clue.Elsewhere, letter: "o" },
          { clue: Clue.Absent, letter: "x" },
        ]}
        annotation={"Close!"}
      />
      <Row
        rowState={RowState.LockedIn}
        wordLength={5}
        cluedLetters={[
          { clue: Clue.Correct, letter: "f" },
          { clue: Clue.Correct, letter: "o" },
          { clue: Clue.Correct, letter: "o" },
          { clue: Clue.Correct, letter: "d" },
          { clue: Clue.Correct, letter: "l" },
        ]}
        annotation={"Correct!"}
      />
      <p>
        To report issues,{" "}
        tweet{" "}
        <a href="https://twitter.com/wordlverse" target="_blank" rel="noopener noreferrer"><strong>@wordlverse</strong></a>
      </p>
      <div className="row buttons-container">
        <div className="column-merch">
          <button className="merch" type="submit">
            <strong><a href="https://www.amazon.com/dp/B09Z2NGMWP" target="_blank" rel="noopener noreferrer">WORDL<br></br>Pillows</a></strong>
          </button>
        </div>
        <div className="column-merch">
          <button className="merch" type="submit">
            <strong><a href="https://www.amazon.com/dp/B09YTWQRQB" target="_blank" rel="noopener noreferrer">WORDL<br></br>PopSocket</a></strong>
          </button>
        </div>          
        <div className="column-merch">
          <button className="merch" type="submit">
            <strong><a href="https://www.amazon.com/dp/B09Z2P24D4" target="_blank" rel="noopener noreferrer">WORDL<br></br>Tote Bag</a></strong>
          </button>
        </div>
        <div className="column-merch">
          <button className="merch" type="submit">
            <strong><a href="https://www.amazon.com/dp/B09YTPKBKR?customId=B07537H64L&th=1" target="_blank" rel="noopener noreferrer">WORDL<br></br>T-shirts</a></strong>
          </button>
        </div>
      </div>
      <p></p>
      <div className="row"> 
        <div className="column-social">
          <a href="https://www.facebook.com/wordlverse" target="_blank" rel="noopener noreferrer"><img src="/facebook-f.svg" width="100%" className="svg-icon2" alt=""></img></a>
        </div>
        <div className="column-social">
          <a href="https://www.instagram.com/wordlverse" target="_blank" rel="noopener noreferrer"><img src="/instagram.svg" width="100%" className="svg-icon2" alt=""></img></a>
        </div>
        <div className="column-social">
          <a href="https://app.wordl.io" target="_blank" rel="noopener noreferrer"><img src="/w-solid.svg" width="100%" className="svg-icon2" alt=""></img></a>
        </div>
        <div className="column-social">
          <a href="https://tiktok.com/@wordlverse" target="_blank" rel="noopener noreferrer"><img src="/tiktok.svg" width="100%" className="svg-icon2" alt=""></img></a>
         </div>
        <div className="column-social">
          <a href="https://twitter.com/wordlverse" target="_blank" rel="noopener noreferrer"><img src="/twitter.svg" width="100%" className="svg-icon2" alt=""></img></a>
        </div>
      </div>
      <br></br>
      <Row
        rowState={RowState.LockedIn}
        wordLength={5}
        cluedLetters={[
          { clue: Clue.Correct, letter: "w" },
          { clue: Clue.Correct, letter: "o" },
          { clue: Clue.Correct, letter: "r" },
          { clue: Clue.Correct, letter: "d" },
          { clue: Clue.Correct, letter: "l" },
        ]}
      />
      <Row
        rowState={RowState.LockedIn}
        wordLength={5}
        cluedLetters={[
          { clue: Clue.Absent, letter: "v" },
          { clue: Clue.Elsewhere, letter: "e" },
          { clue: Clue.Absent, letter: "r" },
          { clue: Clue.Elsewhere, letter: "s" },
          { clue: Clue.Absent, letter: "e" },
        ]}
      />
      <Row
        rowState={RowState.LockedIn}
        wordLength={5}
        cluedLetters={[
          { clue: Clue.Elsewhere, letter: "b" },
          { clue: Clue.Absent, letter: "o" },
          { clue: Clue.Elsewhere, letter: "o" },
          { clue: Clue.Absent, letter: "k" },
          { clue: Clue.Elsewhere, letter: "s" },
        ]}
      />
      <Row
        rowState={RowState.LockedIn}
        wordLength={5}
        cluedLetters={[
          { clue: Clue.Absent, letter: "m" },
          { clue: Clue.Elsewhere, letter: "o" },
          { clue: Clue.Absent, letter: "v" },
          { clue: Clue.Elsewhere, letter: "i" },
          { clue: Clue.Absent, letter: "e" },
        ]}
      />
      <Row
        rowState={RowState.LockedIn}
        wordLength={5}
        cluedLetters={[
          { clue: Clue.Elsewhere, letter: "m" },
          { clue: Clue.Absent, letter: "u" },
          { clue: Clue.Elsewhere, letter: "s" },
          { clue: Clue.Absent, letter: "i" },
          { clue: Clue.Elsewhere, letter: "c" },
        ]}
      />
      <Row
        rowState={RowState.LockedIn}
        wordLength={5}
        cluedLetters={[
          { clue: Clue.Correct, letter: "f" },
          { clue: Clue.Correct, letter: "o" },
          { clue: Clue.Correct, letter: "o" },
          { clue: Clue.Correct, letter: "d" },
          { clue: Clue.Correct, letter: "l" },
        ]}
      />
      <br></br>
    </div>
  );
}
